import React, { useState, useEffect } from "react";
import "../TableStyles.css"

import doubleVenn from '../tableImages/doubleVenn.png';
import doubleVennNA from '../tableImages/doubleVennNA.png';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


function DocMatchOne(props){

    const {resid, reportName} = useParams();
    const {lcChain, hclcChain, hcChain, collName} = props

    useEffect(() =>{
    },[props])

    function applyMatchingPNs(totalQueries){
        for(var i=0; i< totalQueries.length; i++){
            totalQueries[i] = totalQueries[i].toUpperCase();
            totalQueries[i] = totalQueries[i].replaceAll('-','_');
        }
        var abReportFilters = {
            matchingDocuments: totalQueries,
            reportName: decodeURIComponent(reportName) == "undefined"? "Unfiltered_Entire_Report": decodeURIComponent(reportName),
            groupBy: 'document'
        };
        
        return encodeURIComponent(JSON.stringify(abReportFilters))
    }

	return (
        <div class="tableFrame">
            <p class="textFlat pCenter" style={{paddingTop: "13px"}}><strong>DOCUMENTS</strong> matching at least one of the chains</p>
            <div style={{width: "100%", height: "159px", position: "relative"}}>
            {lcChain > 0 &&
             <Link 
                className={lcChain == 0? "textL disabled":"textL"}                        
                target="_blank"
                disabled={lcChain == 0} 
                to={{
                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatchingPNs(['LC']) + "/" + collName
                }}>{lcChain}
             </Link>
            }
            {hclcChain > 0 &&
            <Link 
                className={hclcChain == 0? "textM disabled":"textM"}                         
                target="_blank"
                disabled={hclcChain == 0} 
                to={{
                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatchingPNs(['LC','HC']) + "/" + collName
                }}>{hclcChain}
            </Link>
            }
            {hcChain > 0 &&
            <Link 
                className={hcChain == 0? "textR disabled":"textR"}
                target="_blank"
                disabled={hcChain == 0} 
                to={{
                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatchingPNs(['HC']) + "/" + collName
                }}>{hcChain}
            </Link>
            }
                <span style={{left :"123px"}} className="Circle CircleOrange">
                </span>
                <span style={{right :"123px"}} className={hcChain > 0 ? "Circle CircleBlue" : "Circle CircleNA"}>
                </span>
            </div>
            
        <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
            <span style={{padding: "0 10px"}}>Light Chain</span><span style={{padding: "0 10px"}}>Heavy Chain</span>
        </div>
        <p class="bottomText pCenter">The number of patent documents matching chain sequences. Click the numbers to see the results.</p>
    </div>
    );
}

export default DocMatchOne;
